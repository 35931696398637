
















































































































import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";

const client = axios.create({
  baseURL: process.env.VUE_APP_APIGW_URL,
})

@Component({})
export default class Integration extends Vue {
  errorMsg: string | null = null;
  errorDetail: string | null = null;

  issuer: string | null = null;
  csCall = "";

  lCode = "";
  expirationDate = "";
  vCode: string = this.$route.params.code;

  created(): void {
    if (!this.vCode) {
      this.showError("잘못된 접근입니다.")
    }

    if (!this.validateCode()) {
      this.showError("알 수 없는 발급처입니다.")
    }

    client.post(
        "/tess/v1/license/issue.do",
        {
          iCode: this.vCode,
        }
    ).then((r) => {
      if (r.data?.license?.lCode) {
        this.lCode = r.data.license.lCode;
      }
      if (r.data?.license?.expire) {
        this.expirationDate = this.getExpireDate(r.data?.license?.expire)
      }
      console.log(r)
    }).catch((error) => {
      if (error.response) { // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        if (error.response.status == 418) {
          if (error.response.data?.code == 400) {
            this.showError("잘못된 요청입니다.", error.response.data?.message)
          }
          console.log(error.response.data);
        } else {
          this.showError("처리 중 오류가 발생했습니다. 잠시 후 다시 시도해 주십시오.", error.response.status)
        }
      } else if (error.request) { // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        this.showError("서비스가 응답하지 않습니다.")
        console.log(error.request);
      } else {
        this.showError("알 수 없는 오류가 발생했습니다. 잠시 후 다시 시도해 주십시오.", error.message)
        console.log(error.message);
      }
    })
  }

  validateCode(): boolean {
    const isCode = this.vCode.substr(0, 1)

    switch (isCode) {
      case "k":
      case "K":
      case "T": // FIXME : remove after test
        this.issuer = "KMI"
        return true;
      case "H":
      case "h":
      case "Z":
        this.issuer = "HAII"
        return true;
    }

    return false
  }

  getExpireDate(ts: string): string {
    const date = new Date(ts);
    return date.toLocaleDateString()
  }

  showError(message: string, detail?: string): void {
    this.errorMsg = message;
    if (detail) this.errorDetail = detail;
  }

  goGooglePlay(): void {
    // nothing
  }

  goAppStore(): void {
    // nothing
  }

  launch(): void {
    window.location.href = "tess://screening?institution=" + this.issuer + "&vCode=" + this.vCode + "&lCode=" + this.lCode;
  }
}
