









































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";

const client = axios.create({
  baseURL: process.env.VUE_APP_APIGW_URL,
})

@Component({})
export default class Registration extends Vue {
  $refs!: {
    form: HTMLFormElement
    submit: HTMLButtonElement
  }
  showError = false
  showDialog = false
  errorMsg: string | null = null;
  errorDetail: string | null = null;

  user: string | null = null;
  mobile: string | null = null;
  birth: string | null = null;
  company: string | null = null;
  address: string | null = null;
  radioGroup: number | null = null;
  gender: number | null = null;

  iCode: string | null = null;
  issueUrl: string | null = null

  clearField(): void {
    this.errorMsg = null
    this.errorDetail = null
    this.$refs.form.reset()
    this.$refs.submit.disabled = false

    this.iCode = null
    this.issueUrl = null

    this.clearError()
  }

  clearError(): void {
    this.checkError()
  }

  validate(): boolean {
    if (this.user == null) { return false }
    this.$refs.form

    return true
  }

  addNext(): void {
    this.clearField()
    this.showDialog = false
  }

  submit(): void {
    if (this.validate()) {
      this.$refs.submit.disabled = true

      client.post(
        "/tess/v1/attendee/register",
        {
          name: this.user,
          birthYear: Number(this.birth),
          organization: this.company,
          address: this.address,
          mobile: this.mobile,
          gender: this.gender == 0 ? "M" : "F",
          orgCode: this.radioGroup == 0 ? "990010001" : this.radioGroup == 1 ? "990010002" : this.radioGroup == 2 ? "990010003" : "990000000"
        }
      ).then( (r) => {
        if (r.data?.iCode) {
          this.iCode = r.data.iCode
          this.issueUrl = process.env.VUE_APP_WEB_URL + "/i/" + r.data.iCode
          console.log("received iCode " + this.iCode + "\nissueUrl " + this.issueUrl)
          this.showDialog = true
        }
        this.$refs.submit.disabled = false
      }).catch( (error) => {
        this.$refs.submit.disabled = false
        if (error.response) {
          if (error.response.status == 418) {
            this.checkError("Bad Request", error.response.data?.message)
            console.log(error.response.data)
          } else {
            this.checkError("오류 발생", error.response.status)
          }
        } else if (error.request) {
          console.log(error.request)
          this.checkError("서비스 응답 없음")
        } else {
          console.log(error.message)
          this.checkError("알 수 없는 오류")
        }
      })

    } else {
      this.checkError("입력 값 오류")
    }
  }

  checkError(msg: string | null = null, detail?: string): void {
    this.errorMsg = msg
    if (detail) this.errorDetail = detail
    this.showError = this.errorMsg != null
  }


}
