







import Vue from "vue";
import Component from "vue-class-component";
import router from "@/router";

@Component({})
export default class Home extends Vue {
  created(): void {
    window.location.href = "https://haii.io";
  }
}
